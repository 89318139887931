import React from 'react';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { useTranslation } from 'react-i18next';

const BannerContainer = styled.div`
  padding: 0rem 2rem 1.5rem;

  @media ${Queries.md} {
    padding: 0rem 2.5rem 2rem;
  }
  img {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 8px;
  }
  margin-bottom: 1rem;
`;

const FeaturedBanner = ({ image, link }) => {
  const { t } = useTranslation();
  if (!image || !link) {
    return null;
  }
  // todo: maker helper func for this, atm only works for repeatable content types
  const pathPrefix = link.type === 'custom' ? '' : `${link.type}/`;
  const url = link.url || `${pathPrefix + link.uid}`;
  return (
    <BannerContainer>
      <a href={url}>
        <img src={image.url} alt={image.alt} />
      </a>
    </BannerContainer>
  );
};

export default FeaturedBanner;
