import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Colors, Queries } from 'styles';

const SLIDER_INTERVAL = 12000;

const CarouselContainer = styled.div`
  padding: 1.5rem 1.5rem;
  background-color: ${Colors.gdmc_blue};

  & > .title {
    /* color: ${Colors.gdmc_blue}; */
    color: white;
    font-size: 1.75rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .carousel {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }

  .newsSlot {
    border-radius: 6px;
    background-color: ${Colors.bg_lite};
    padding: 2rem 1rem;
    flex-basis: 100%;
    max-width: 100%;
    flex-shrink: 0;
    transition: 0.3s ease-in-out transform, 0.3s ease-in-out opacity;

    @media ${Queries.md} {
      flex-basis: 80%;
      padding: 2rem;
    }

    &.active {
      transform: none;
      opacity: 1;
    }

    &.prev,
    &.next {
      position: absolute;
      opacity: 0;
      left: 0;
    }

    &.prev {
      transform: translateX(-100%);
    }

    &.next {
      transform: translateX(100%);
    }

    .title {
      font-size: 1.25rem;
      margin: 0 0 1rem 0;
    }
  }

  .carousel-indicators {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    & > .indicator {
      cursor: pointer;
      width: 28px;
      height: 8px;
      background-color: ${Colors.grey_lite};
      margin: 0 0.375rem;

      &.active {
        background-color: ${Colors.gdmc_orange};
      }
    }
  }

  .newsContent {
    a { 
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
`;

const NewsCarousel = ({ news }) => {
  const { t } = useTranslation();
  const [currSlide, setCurrSlide] = useState(0);
  const showNextSlide = useCallback(() => {
    if (currSlide >= news.length - 1) {
      setCurrSlide(0);
    } else {
      setCurrSlide(currSlide + 1);
    }
  }, [currSlide, news]);
  const getSlideClass = (idx) => {
    if (idx < currSlide) {
      return 'prev';
    } else if (idx > currSlide) {
      return 'next';
    }
    return 'active';
  };
  useEffect(() => {
    if (news.length > 1) {
      let timer = setTimeout(showNextSlide, SLIDER_INTERVAL);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showNextSlide, news]);
  return (
    <CarouselContainer>
      <h2 className="title">{t('latest_news')}</h2>
      <div className="carousel">
        {news.map((item, idx) => (
          <div key={idx} className={`newsSlot ${getSlideClass(idx)}`}>
            <h3 className="title">{item.news_title.text}</h3>
            <div
              className="newsContent"
              dangerouslySetInnerHTML={{ __html: item.news_description.html }}
            />
          </div>
        ))}
      </div>
      <div className="carousel-indicators">
        {news.map((_, idx) => (
          <div
            key={idx}
            onClick={() => setCurrSlide(idx)}
            className={`indicator ${getSlideClass(idx)}`}
          />
        ))}
      </div>
    </CarouselContainer>
  );
};

export default NewsCarousel;
