import React from 'react';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { useTranslation } from 'react-i18next';

const ClientListContainer = styled.div`
  padding: 1.5rem 2rem;

  @media ${Queries.md} {
    padding: 2rem 2.5rem;
  }

  & > .title {
    font-size: 1.75rem;
    color: ${Colors.gdmc_blue};
    text-align: center;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .clientLogos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 720px;
    margin: 0 auto;
  }

  .clientLogo {
    width: 160px;
    height: 120px;
    position: relative;

    & > img {
      padding: 1rem;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      /* transition: 0.2s ease-in-out opacity, 0.2s ease-in-out filter; */
      object-fit: contain;

      @media ${Queries.md} {
        /* filter: grayscale(100%); */
      }

      &:hover {
        opacity: 1;
        /* filter: none; */
      }
    }
  }
`;

const ClientList = ({ clients }) => {
  const { t } = useTranslation();
  return (
    <ClientListContainer>
      <h2 className="title">{t('our_clients')}</h2>
      <div className="clientLogos">
        {clients.map((client, idx) => (
          <div key={idx} className="clientLogo">
            <img src={client.client_logo.url} alt={client.client_logo.alt} />
          </div>
        ))}
      </div>
    </ClientListContainer>
  );
};

export default ClientList;
