import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import NewsCarousel from 'components/NewsCarousel';
import ClientList from 'components/ClientList';
import FeaturedBanner from 'components/FeaturedBanner';
import HeroImg from 'assets/images/gdmc_hero.jpg';
import SEO from 'components/seo';
import styled from 'styled-components';
import { Colors, Queries } from 'styles';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { getLangData } from 'utils';

const HomeContent = styled.main`
  .hero {
    background: linear-gradient(${Colors.shadow_lite}, ${Colors.shadow_lite}),
      url(${HeroImg});
    background-size: cover;
    background-position: center;
    text-align: center;
    overflow: auto;
    padding: 8rem 1rem;
    color: white;
    text-shadow: ${Colors.shadow} 0px 2px 3px;
    background-color: rgba(0, 0, 0, 0.5);

    @media ${Queries.md} {
      padding: 10rem 1rem;
    }

    @media ${Queries.lg} {
      padding: 14rem 1rem;
    }

    h1 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0.75rem;

      @media ${Queries.md} {
        font-size: 2.5rem;
      }
    }

    p {
      font-size: 1.25rem;
      margin: 0;

      @media ${Queries.md} {
        font-size: 1.5rem;
      }
    }
  }

  & > .content {
    background-color: ${Colors.bg_lite};

    & > .intro {
      padding: 1.5rem 2rem;

      @media ${Queries.md} {
        padding: 2rem 2.5rem;
      }

      & > .title {
        font-size: 1.25rem;

        @media ${Queries.md} {
          font-size: 1.5rem;
        }
      }
    }
  }
`;

const Home = ({ location, data }) => {
  const { i18n, t } = useTranslation();
  const homeData = data.prismicHome.data;
  return (
    <Layout location={location}>
      <SEO
        lang={i18n.language}
        title={t('home')}
        description={homeData.heading.text}
      />
      <Container>
        <HomeContent>
          <div className="hero">
            <h1>{homeData.title.text}</h1>
            <p>{homeData.tagline.text}</p>
          </div>
          <div className="content">
            <div className="intro">
              <h2 className="title">{homeData.heading.text}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: homeData.description.html }}
              />
            </div>
            {homeData.banners.map((banner) => (
              <FeaturedBanner image={banner.image} link={banner.link} />
            ))}
            {/* {homeData.news?.length ? (
              <NewsCarousel news={homeData.news} />
            ) : null} */}
            <ClientList clients={homeData.client_list} />
          </div>
        </HomeContent>
      </Container>
    </Layout>
  );
};

// $lang is a variable received from createPage context (gatsby-helpers.js)
export const query = graphql`
  query HomePageQuery($lang: String) {
    prismicHome(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        heading {
          text
        }
        tagline {
          text
        }
        description {
          html
        }
        client_list {
          client_logo {
            url
            alt
          }
        }
        banners {
          image {
            url
            alt
          }
          link {
            url
            type
            uid
          }
        }
      }
    }
  }
`;

// TODO: add this back later
// news {
//   news_description {
//     html
//   }
//   news_title {
//     text
//   }
// }
// banners {
//   image {
//     url
//     alt
//   }
//   link {
//     url
//     type
//     uid
//   }
// }

export default Home;
